import * as React from "react";
import { AddToCartTireButtonCard } from "../../cart/addToCartTireButton/addToCartTireButton";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Chip from "@mui/material/Chip";
import * as StyledComponets from "./tireCard.module.scss";
import winter from "../../../icons/winter.svg";

// markup
const TireCard = ({
  image,
  sku,
  brand,
  model,
  price,
  diam,
  aspect,
  width,
  season,
  ply,
  lt,
  qwinter,
  psi,
  loadRange,
}) => {
  //var to change the currency formats
  var formatter = new Intl.NumberFormat("fr-CA", {
    style: "currency",
    currency: "CAD",
  });
  function isWinterProof() {
    if (season === "winter") {
      //homologues
      return (
        <div className={StyledComponets.snow}>
          <Chip
            icon={<ControlPointIcon />}
            label="DÉTAILS"
            variant="outlined"
            color="success"
          />
          <img className={StyledComponets.snow} src={winter} alt="winter" />
        </div>
      );
    }
    if (season === "summer") {
      //Non homologues
      return (
        <div className={StyledComponets.snow}>
          <Chip
            icon={<ControlPointIcon />}
            label="DÉTAILS"
            variant="outlined"
            color="success"
          />
        </div>
      );
    }
    if (season === "4season") {
      if (qwinter === true) {
        //homologues - 4season
        return (
          <div className={StyledComponets.snow}>
            <Chip
              icon={<ControlPointIcon />}
              label="DÉTAILS"
              variant="outlined"
              color="success"
            />
            <img className={StyledComponets.snow} src={winter} alt="winter" />
          </div>
        );
      }
      if (qwinter === false) {
        // non homologues - 4season
        return (
          <div className={StyledComponets.snow}>
            <Chip
              icon={<ControlPointIcon />}
              label="DÉTAILS"
              variant="outlined"
              color="success"
            />
          </div>
        );
      }
    }
  }
  function disPly() {
    if (ply !== 0) {
      return (
        <div className={StyledComponets.ply}>
          <h5>{ply} ply</h5>
        </div>
      );
    } else {
      return <div className={StyledComponets.ply}></div>;
    }
  }
  function disLT() {
    if (lt === true) {
      return <h5>LT{width + "/" + aspect + "R" + diam}</h5>;
    } else {
      return <h5>{width + "/" + aspect + "R" + diam}</h5>;
    }
  }
  let URL = "/tire?sku=" + sku;
  return (
    <article className={StyledComponets.card} key={sku}>
      <a href={URL} target="_blank" rel="noreferrer">
        <div className={StyledComponets.snowContainer}>{isWinterProof()}</div>
        <h3>{brand}</h3>
        <h4>{model}</h4>
        {disLT()}
        {disPly()}
        <img
          className={StyledComponets.magimage}
          loading="lazy"
          src={image}
          alt={brand + " " + width + "/" + aspect + "R" + diam}
        />
        <p className={StyledComponets.sku}>{sku}</p>
        <div className={StyledComponets.price}>
          <h2>{formatter.format(price)}</h2>
          <p>&nbsp;ch.</p>
        </div>
      </a>
      <AddToCartTireButtonCard
        ximage={image}
        xsku={sku}
        xbrand={brand}
        xmodel={model}
        xprice={price}
        xwidth={width}
        xaspect={aspect}
        xdiam={diam}
        xseason={season}
      />
    </article>
  );
};
export default TireCard;
